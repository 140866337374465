.App {
  text-align: center;
  padding: 20px;
}

form {
  margin-bottom: 20px;
}

label {
  margin-right: 10px;
}

input,
select {
  margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  cursor: pointer;
}

.result {
  margin-top: 20px;
}

.result p {
  font-size: 18px;
}
